import App from "../App";
import { RecoilRoot } from "recoil";
import { ThemeProvider } from "styled-components";
import theme, { GlobalStyle } from "../styles/theme";
import { BrowserRouter } from "react-router-dom";

function Root() {
  return (
    <RecoilRoot>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </RecoilRoot>
  );
}

export default Root;
