import SolutionCard from "../components/solution/SolutionCard";
import TopBanner from "../components/TopBanner";
import Layout from "../layout";
import cover from "./cover";
import solutionData from "./solutionData";

const Solution = () => {
  const { title, imageUrl, content } = cover.solution;

  return (
    <Layout>
      <TopBanner imageUrl={imageUrl} content={content} title={title} />
      {solutionData.map((item) => (
        <SolutionCard key={item.id} data={item} />
      ))}
    </Layout>
  );
};

export default Solution;
