import styled from "styled-components";
import { Project } from "../home/MainContentList";
import { response } from "../../styles/theme";
import setImageUrl from "../../lib/setImageUrl";
import { bigWidthThanPadWidth } from "../../lib/replaceJsx";

interface IProps {
  onClick: any;
  style: any;
  project: Project;
}

const Card = ({ project, onClick, style }: IProps) => {
  const { title, shortDescription, excerpt, categorys, coverImage } = project;

  const checkTypes = (type: any) => {
    if (categorys) {
      let result = false;
      categorys.forEach((category) => {
        if (category === type) {
          result = true;
        }
      });
      return result;
    }
  };
  return (
    <Container style={style} onClick={onClick}>
      <ImageBox imageUrl={setImageUrl(coverImage)} />
      <ContentBox>
        <ShortDescription>{shortDescription}</ShortDescription>
        <Title>{title}</Title>
        <Excerpt>{bigWidthThanPadWidth(excerpt || "")}</Excerpt>
        <Category>
          <span style={checkTypes("AOS") ? { color: "#CA9D65" } : {}}>AOS</span>
          <span style={checkTypes("iOS") ? { color: "#CA9D65" } : {}}>iOS</span>
          <span style={checkTypes("WEB") ? { color: "#CA9D65" } : {}}>WEB</span>
        </Category>
      </ContentBox>
    </Container>
  );
};

const Container = styled.div`
  cursor: pointer;
  margin: 0 25px 24px;
  display: flex;
  color: ${({ theme }) => theme.mainTitle};
  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width: ${response.pad}px) {
    margin-bottom: 48px;
    flex-direction: column;
  }
`;
const ContentBox = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  @media (max-width: ${response.pad}px) {
    margin-top: 24px;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  margin-bottom: 8px;
`;
const ShortDescription = styled.span`
  margin-bottom: 8px;
  font-weight: 300;
  font-size: 16px;
  line-height: 120%;
  color: ${({ theme }) => theme.caption};
`;

const Excerpt = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.subText};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  margin-bottom: 12px;
  @media (max-width: ${response.pad}px) {
    font-size: 14px;
  }
`;
const Category = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  justify-content: flex-end;
  & > span {
    display: inline-block;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
  color: #ebebeb;
`;

const ImageBox = styled.div<{ imageUrl: string }>`
  background-color: #fff;
  border-radius: 10px;
  height: 180px;
  width: 360px;
  background: no-repeat center / cover url(${({ imageUrl }) => imageUrl});
  margin-right: 32px;
  @media (max-width: ${response.pad / 2}px) {
    width: 100%;
  }
  @media (min-width: ${response.pad / 2 +
    1}px) and (max-width: ${response.pad}px) {
    height: 230px;
    width: 100%;
  }
`;

Card.defaultProps = {
  onClick: () => {},
  style: {},
  new: {
    author: "",
    coverImage: "",
    date: "",
    description: "",
    email: "",
    excerpt: "",
    slug: "",
    title: "",
  },
};

export default Card;
