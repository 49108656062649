interface History {
  [index: string]: any[];
}

export const history: History = {
  2022: [{ month: "01", title: "피터팬의 좋은방 구하기 웹 개발" }],
  2021: [{ month: "02", title: "바디프랜드 라운지 앱 개발" }],
  2020: [{ month: "04", title: "바디프랜드 멤버십 앱 개발" }],
  2019: [
    { month: "01", title: "패션플로우  as-is, 타임픽, 플로우진 앱&웹 개발" },
    { month: "08", title: "바디프랜드 라운지 앱 개발" },
  ],
  2018: [
    { month: "05", title: "반려동물 통합 솔루션 PPPL 개발" },
    { month: "09", title: "온라인 전자계약서 공유브릿지 서비스" },
  ],
  2017: [
    { month: "09", title: "짬짬이 공동 개발 및 정식 런칭" },
    { month: "09", title: "짬짬이 일하자 공동 개발 및 정식 런칭" },
  ],
  2016: [
    { month: "02", title: "파이엇 소프트 설립" },
    {
      month: "05",
      title: "스테이션3과 협업하여 월세간편결제 솔루션, 다방페이 개발",
    },
  ],
};

export default { history };
