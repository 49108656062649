import styled from "styled-components";
import theme from "../../styles/theme";

interface IProps {
  width: number;
  height: number;
  fill: string;
  style: any;
}

const ArrowTop = ({ width, height, fill, style }: IProps) => {
  return (
    <Container width={width} height={height} style={style}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.69535 19.7416C4.33593 19.3223 4.38449 18.691 4.80382 18.3315L14.3493 10.1497C14.7238 9.82874 15.2764 9.82874 15.6509 10.1497L25.1963 18.3315C25.6156 18.691 25.6642 19.3223 25.3048 19.7416C24.9454 20.1609 24.3141 20.2095 23.8947 19.8501L15.0001 12.2261L6.1054 19.8501C5.68607 20.2095 5.05477 20.1609 4.69535 19.7416Z"
          fill={fill}
        />
      </svg>
    </Container>
  );
};

ArrowTop.defaultProps = {
  width: 24,
  height: 24,
  fill: theme.mainTitle,
  style: {},
};

const Container = styled.div<{ width: number; height: number }>`
  cursor: pointer;
  padding: 4px;
  width: ${({ width }) => width + "px"};
  height: ${({ height }) => height + "px"};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export default ArrowTop;
