import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import MoreButton from "../components/MoreButton";
import Card from "../components/project/Card";
import TopBanner from "../components/TopBanner";
import Layout from "../layout";
import cover from "./cover";
import { project } from "./projectData";

const LIST_MAX_COUNT = 5;

const Project = () => {
  const { imageUrl, title, content } = cover.project;
  const [maxCount, setMaxCount] = useState(LIST_MAX_COUNT);
  const navigate = useNavigate();

  const clickMoreBtn = () => {
    setMaxCount(maxCount + LIST_MAX_COUNT);
  };

  const moveNewsDetail = (project: any) => {
    navigate(`/project/${project.slug}`, { state: project });
  };

  return (
    <Layout>
      <TopBanner imageUrl={imageUrl} title={title} content={content} />
      <Container>
        {project
          .filter((el) => !!el.excerpt)
          .filter((el) => el.title !== "짬짬이")
          .map((item, index) => {
            if (index < maxCount)
              return (
                <Card
                  key={index}
                  project={item}
                  onClick={() => moveNewsDetail(item)}
                />
              );
          })}
      </Container>
      <div style={{ marginBottom: "80px" }}>
        {maxCount < project.length && <MoreButton onClick={clickMoreBtn} />}
      </div>
    </Layout>
  );
};

const Container = styled.div`
  margin: 80px 0;
  width: 100%;
  max-width: 1000px;
`;

export default Project;
