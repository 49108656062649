export const content = [
  {
    id: 0,
    title: "연구개발",
    description: "",
    content: [
      {
        id: 0,
        title: "모바일기기를 활동한 FIDO인증 연구",
        description:
          "빠르고 안전한 인증방법을 통해 운영중인 서비스(인력공급, 전자서명 등)에 적용",
        imageUrl: "images/img_solution-1.png",
      },
      {
        id: 1,
        title: "인력 매칭 알고리즘 연구",
        description:
          "빅데이터 분석 기술이 발달함에 따라 사용자의 패턴을 분석해 취향을 예측하고,\n사용자와 기업간의 먼저 추천해주는 ‘매칭’ 알고리즘 구축",
        imageUrl: "images/img_solution-2.png",
      },
    ],
  },
  {
    id: 1,
    title: "자동결제 시스템",
    description:
      "현금으로만 지불 가능했던 자동결제 프로세스의 단점을 보완하기 위해\n카드결제를 도입하여, 현금부족으로 발생하는 결제 지연의 최소화",
    content: [
      {
        id: 0,
        title: "카드 간편결제 도입",
        description:
          "카드 결제 도입으로 발생하는 결제수수료는 내 최저의 수수료가 적용\n자동결제도 누적된 정보는 통계자료를 통해 이용기관의 효과적인 운영을 제시",
        imageUrl: "images/img_solution-3.png",
      },
    ],
  },
  {
    id: 2,
    title: "매칭 알고리즘",
    description:
      "이용기관의 정보를 분석하여 저장한 후, 이후 저장된 정보의 활동으로\n수집된 추가정보를 학습시켜 보다 빠르고 효과적인 정보 간 매칭 시스템을 제공합니다.",
    content: [
      {
        id: 0,
        title: "매칭 프로세스",
        description:
          "각 기관마다 구축된 DB를 효과적으로 정보를 재구성 및 재해석이 가능\n광범위하게 분포된 정보를 분석하여 연관성, 비중도 등 추가정보를 끌어낼 수 있음",
        imageUrl: "images/img_solution-4.png",
      },
    ],
  },
  {
    id: 3,
    title: "문서 전자인증",
    description:
      "온라인으로 주고받은 문서의 본인인증과 전자 서명으로 보다 안전하게 문서를 공유하며,\n해당 시스템을 이용기관의 시스템과 결합할 수 있습니다.",
    content: [
      {
        id: 0,
        title: "인증처리 프로세스",
        description:
          "보안 기술의 안정성을 기반으로 구축된 인증시스템을 통해 간편하게 스마트기기를 통해 인증절차 진행\n암호화된 문서는 인증 후 전달받은 정보를 결합하여 보안DB에 저장\n문서확인 시 인증서버를 통해 본인검증 후 보안 DB에 문서의 위/변조를 확인",
        imageUrl: "images/img_solution-5.png",
      },
    ],
  },
];
export default content;
