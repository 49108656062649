import styled, { css, createGlobalStyle } from "styled-components";

export const response = {
  desktop: 1200,
  pad: 768,
  mobile: 360,
};
export const px2vw = (size: number, width = response.pad) =>
  `${(size / width) * 100}vw`;
export const GlobalStyle = createGlobalStyle`
  /* 추가 공통 스타일  */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  :root {
    /* 모바일 - 패드 중간값  필요할때 */
    @media (max-width: ${response.pad / 2}px) {}
    @media (min-width: ${response.pad / 2 + 1}px) and (max-width: ${
  response.pad
}px) {}
    /* 3단 반응형 */
    @media (max-width: ${response.pad}px) { }
    @media (min-width: ${response.pad + 1}px) and (max-width: ${
  response.desktop
}px) {  }
    @media (min-width: ${response.desktop + 1}px) {  }
  }
`;

export const theme = {
  // 배경화면
  bgMain: `#fff`,
  bgSub: `#FBFBFB`,
  bgDark: `#24252F`,
  // border
  inputBorder: `rgba(221, 221, 221, 1)`,
  listBorder: `rgba(238, 238, 238, 1)`,
  // text
  mainTitle: `rgba(36, 36, 36, 1)`,
  subTitle: `rgba(117, 117, 117, 1)`,
  headerText: `rgba(66,66,66,1)`,
  listTitle: `rgba(56, 56, 56, 1)`,
  listText: `rgba(54,54,54,1)`,
  caption: `rgba(152, 152, 152, 1)`,
  inputHintText: `rgba(97,97,97,1)`,
  disable: `rgba(224, 224, 224, 1)`,
  white: `rgba(255, 255, 255, 1)`,
  // button
  bgBtnBk: `rgba(36,37,47,0.6)`,
  bgBtnPrimary: `#AF9883`,
  bgShadow: `8px 8px 16px rgba(0, 0, 0, 0.05)`,
  topBtnShadow: `3px 3px 10px rgba(0, 0, 0, 0.1)`,
  response,
  px2vw,
};
export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Roboto = css`
  /* 기본 폰트 이외 폰트 추가될 경우 Roboto와 같이 이름 변경하여 추가 */
  font-family: "Roboto", "Noto Sans KR", sans-serif;
`;
export const RobotoBold = css`
  ${Roboto}
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
`;

export const unit = 4;

/**
 * 공통 컴포넌트
 */
export const Title = styled.p<{ size: string; align: string }>`
  font-size: ${({ size }) => (size === "small" ? "16px" : "20px")};
  line-height: ${({ size }) => (size === "small" ? "130%" : "150%")};
  text-align: ${({ align }) => align};
  font-weight: 200;
  strong {
    font-weight: 500;
  }
  @media (max-width: ${response.pad}px) {
    /* font-size: ${({ size }) => (size === "small" ? "14px" : "18px")}; */
  }
`;

export const SubText = styled.p`
  text-align: Center;
  font-size: 16px;
  line-height: 150%;
  color: #989898;
  @media (max-width: ${response.pad}px) {
    /* font-size: 14px; */
  }
`;

export const SampleBox = styled.div`
  background-color: greenyellow;
`;

export const MarginBottom16 = styled.div`
  margin-bottom: 16px;
`;
export default theme;
