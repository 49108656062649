import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Contact from "../components/contact/Contact";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ArrowTop from "../components/svg/ArrowTop";
import { width } from "../store/store";
import { response } from "../styles/theme";
import { useRecoilState } from "recoil";

interface IProps {
  children: React.ReactNode;
}

const Layout = ({ children }: IProps) => {
  const layoutRef = useRef(null);
  const [fixHeader, setFixHeader] = useState(false); // true 검정글씨+흰배경
  const [showTopBtn, setShowTopBtn] = useState(true);
  const [pageYOffset, setPageYOffset] = useState(0);
  const [innerWidth, setInnerWidth] = useRecoilState(width);

  const handlerResize = useCallback(
    (e: Event) => {
      e.preventDefault();
      const currentInnerWidth = window.innerWidth;
      setInnerWidth(currentInnerWidth);
    },
    [setInnerWidth]
  );

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlerScroll = (e: any) => {
    e.preventDefault();
    setPageYOffset(window.pageYOffset);
  };

  useEffect(() => {
    const TopBannerHeight = 640;
    if (pageYOffset > TopBannerHeight / 2) {
      setShowTopBtn(true);
    } else {
      setShowTopBtn(false);
    }
    if (pageYOffset > TopBannerHeight) {
      setFixHeader(true);
    } else {
      setFixHeader(false);
    }
  }, [pageYOffset]);

  useEffect(() => {
    // 스크롤 감지
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handlerScroll, false);
      return () => window.removeEventListener("scroll", handlerScroll, false);
    }
  }, []);

  useEffect(() => {
    // 화면폭 감지
    if (innerWidth === null && typeof window !== "undefined") {
      setInnerWidth(window.innerWidth);
      window.addEventListener("resize", handlerResize);
      return () => window.removeEventListener("resize", handlerResize);
    }
  }, []);

  return (
    <LayoutContainer modal={false} ref={layoutRef}>
      <div className="fixed-scroll">
        <Contact />
        {showTopBtn && (
          <FixedTopButton onClick={scrollToTop}>
            <ArrowTop fill={"white"} width={35} height={35} />
          </FixedTopButton>
        )}
        <Header fixHeader={fixHeader} />
        <CenterBox>{children}</CenterBox>
        <Footer />
      </div>
    </LayoutContainer>
  );
};

const LayoutContainer = styled.div<{ modal: boolean }>`
  color: ${({ theme }) => theme.mainTitle};
  display: flex;
  flex-direction: column;
  justify-content: center;
  .fixed-scroll {
    position: relative;
    overflow: ${({ modal }) => (modal ? "hidden" : "auto")};
  }
`;
const CenterBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const FixedTopButton = styled.div`
  position: fixed;
  padding: 2px;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.bgDark};
  @media (max-width: ${response.pad}px) {
    background-color: ${({ theme }) => theme.bgBtnBk};
    box-shadow: ${({ theme }) => theme.topBtnShadow};
  }
`;

export default Layout;
