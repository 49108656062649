import { response } from "../styles/theme";

export const replaceBrTag = (string: any) => {
  let results = [];
  if (typeof string === "string") {
    const regBr = new RegExp("<br/>", "g");
    const setResult = (strings: string[]) => {
      strings.forEach((str: string, index: number) => {
        if (str && str !== "") results.push(str.trim());
        if (index !== strings.length - 1) {
          results.push(<br key={index} />);
        }
      });
    };
    if (regBr.test(string)) {
      const strings = string.split(regBr);
      setResult(strings);
    } else if (string.includes("\n")) {
      const strings = string.split("\n");
      setResult(strings);
    } else {
      results.push(string);
    }
  }
  return results;
};

export const bigWidthThanPadWidth = (
  string: string,
  innerWidth = response.desktop,
  baseWidth = (response.pad / 100) * 60
) => {
  if (innerWidth && innerWidth < baseWidth) {
    let result = string.replace("\n", " ");
    result = result.replace("<br/>", " ");
    result = result.replace("<br />", " ");
    return result;
  }
  return replaceBrTag(string);
};

export const replaceStrongText = (string: string, strongText: string) => {
  const strings = string.split(strongText);
  let results: any[] = [];
  strings.forEach((str, index) => {
    results.push(...replaceBrTag(str));
    if (index !== strings.length - 1) {
      results.push(<strong key={index}>{strongText}</strong>);
    }
  });
  return results;
};

export default {
  replaceStrongText,
};
