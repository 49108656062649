import styled from "styled-components";
import theme from "../../styles/theme";
import ArrowLeft from "../svg/ArrowLeft";
import ArrowRight from "../svg/ArrowRight";

interface IProps {
  style: any;
  maxLength: number;
  currentId: number;
  nextPage: any;
  prevPage: any;
}

const Controller = ({ currentId, maxLength, prevPage, nextPage }: IProps) => (
  <Container>
    <ArrowLeft
      width={36}
      height={36}
      style={{ marginRight: "10px" }}
      onClick={prevPage}
    />
    <ArrowRight width={36} height={36} onClick={nextPage} />
    {maxLength > 0 && (
      <span style={{ marginLeft: "10px" }}>
        {currentId + 1} / {maxLength}
      </span>
    )}
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: center;
`;

Controller.defaultProps = {
  style: {},
  currentId: null,
  maxLength: null,
  prevPage: () => {},
  nextPage: () => {},
};

export default Controller;
