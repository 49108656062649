import { Route, Routes } from "react-router-dom";
import Detail from "./components/news/Detail";
import ProjectDetail from "./components/project/ProjectDetail";
import About from "./pages/About";
import Home from "./pages/Home";
import News from "./pages/News";
import Project from "./pages/Project";
import Solution from "./pages/Solution";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/news" element={<News />} />
      <Route path="/project" element={<Project />} />
      <Route path="/solution" element={<Solution />} />
      <Route path="news/:id" element={<Detail />} />
      <Route path="project/:id" element={<ProjectDetail />} />
    </Routes>
  );
}

export default App;
