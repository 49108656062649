import styled from "styled-components";
import menu from "./images/ic_menu_wh.png";
import theme from "../../styles/theme";

interface IProps {
  width: number;
  height: number;
  style: any;
  onClick: () => void;
}

const Hamberger = ({ width, height, style, onClick }: IProps) => {
  return (
    <Container width={width} height={height} style={style} onClick={onClick}>
      <img src={menu} alt="" width={25} />
    </Container>
  );
};

Hamberger.defaultProps = {
  width: 24,
  height: 24,
  fill: theme.mainTitle,
  style: {},
  onClick: () => {},
};

const Container = styled.div<{ width: number; height: number }>`
  cursor: pointer;
  width: ${({ width }) => width + "px"};
  height: ${({ height }) => height + "px"};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;
export default Hamberger;
