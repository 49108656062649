export const name = { kor: "파이엇 소프트", eng: "Piot soft" };
export const slogun =
  "파이엇 소프트는 고객과 더 나은\n소통을 위해 발전하고 있습니다.";
export const logo = {
  dark: {
    small: "images/img_logo-wt.png",
    medium: "images/img_logo-wt@2x.png",
    large: "images/img_logo-wt@3x.png",
  },
  light: {
    small: "images/img_logo-bk.png",
    medium: "images/img_logo-bk@2x.png",
    large: "images/img_logo-bk@3x.png",
  },
};
export const nav = [
  { id: 0, name: "About", url: "/about" },
  { id: 3, name: "Project", url: "/project" },
];
export const address = "서울시 강남구 영동대로 730 5층 파이엇 소프트";
export const googleMapUrl =
  "https://www.google.com/maps/place/%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C+%EA%B0%95%EB%82%A8%EA%B5%AC+%EC%98%81%EB%8F%99%EB%8C%80%EB%A1%9C+730/data=!3m1!4b1!4m5!3m4!1s0x357ca4641efe9e8b:0xd4c11375fe9ef64e!8m2!3d37.5232008!4d127.0559459";
export const tel = "02-3444-8525";
export const fax = "02-548-8525";
export const email = "dev@piotsoft.com";

export default {
  googleMapUrl,
  name,
  logo,
  address,
  slogun,
  tel,
  fax,
  email,
  nav,
};
