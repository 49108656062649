export const setImageUrl = (tailPath: any) => {
  const dev = process.env.NODE_ENV === "development";
  const domain = dev
    ? process.env.NEXT_PUBLIC_LOCAL_HOST
    : process.env.NEXT_PUBLIC_AWS_S3_BASE_URL;
  const paths = [domain, tailPath];
  const fullpath = paths.join("/");
  return fullpath;
};

export const getMobileImageUrl = (fullpath: string) => {
  // 모바일 이미지의 경우 웹용 이미지 이름 + 'm'을 붙여 사용함
  const [path, exp] = fullpath.split(".");
  return path + "m." + exp;
};

export default setImageUrl;
