import PropTypes from "prop-types";
import { replaceStrongText } from "../../lib/replaceJsx";
import { Title } from "../../styles/theme";

interface IProps {
  align: "center" | "right" | "left";
  size: "small" | "large";
  url: string;
  content: {
    title: string;
    strongText: string;
  };
}

const TitleCompo = ({ content, size, align, url }: IProps) => {
  const { title, strongText } = content;
  return (
    <Title size={size} align={align}>
      {replaceStrongText(title, strongText).map((str, index) => {
        return <span key={index}>{str}</span>;
      })}
    </Title>
  );
};
TitleCompo.defaultProps = {
  align: "center",
  size: "small",
  url: "",
  content: {
    title: "",
    strongText: "",
  },
};
TitleCompo.propTypes = {
  center: PropTypes.oneOf(["center", "right", "left"]),
  size: PropTypes.oneOf(["small", "large"]),
  url: PropTypes.string,
  content: PropTypes.shape({
    title: PropTypes.string,
    strongText: PropTypes.string,
  }),
};

export default TitleCompo;
