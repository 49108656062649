import styled from "styled-components";
import setImageUrl from "../lib/setImageUrl";
import { response } from "../styles/theme";
import ContactButton from "./ContactButton";

interface IProps {
  imageUrl: string;
  content: string;
  title: string;
}

const TopBanner = ({ imageUrl, content, title }: IProps) => {
  return (
    <TopBannerContainer imageUrl={setImageUrl(imageUrl)}>
      <CenterBox>
        <RouteName>{title}</RouteName>
        {content.split("\n").map((str, index) => {
          return <Description key={index}>{str}</Description>;
        })}
        <ContactButton />
      </CenterBox>
    </TopBannerContainer>
  );
};

export const TopBannerContainer = styled.div<{ imageUrl: string }>`
  position: relative;
  color: white;
  width: 100%;
  height: 640px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  transition: 1s;
  background: center / cover no-repeat url(${({ imageUrl }) => imageUrl});
`;

const CenterBox = styled.div`
  max-width: ${response.desktop + "px"};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  position: relative;
  @media (max-width: ${response.pad}px) {
    align-items: flex-start;
    padding: 0 40px;
    font-weight: 500;
  }
`;
export const SlideCard = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
export const RouteName = styled.h1`
  text-align: center;
  font-weight: 200;
  font-size: 60px;
  @media (max-width: ${response.pad}px) {
    font-size: 40px;
    font-weight: 500;
    text-align: left;
  }
`;
export const Description = styled.p`
  font-size: 18px;
  line-height: 1.5em;
  text-align: center;
  @media (max-width: ${response.pad}px) {
    font-size: 16px;
    font-weight: 200;
    text-align: left;
  }
`;

export default TopBanner;
