import styled from "styled-components";
import theme from "../../styles/theme";

interface IProps {
  width: number;
  height: number;
  fill: string;
  style: any;
  onClick: any;
}

const Close = ({ width, height, fill, style, onClick }: IProps) => {
  return (
    <Container width={width} height={height} style={style} onClick={onClick}>
      <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.3124 21.9999L21.9999 21.3124L11.6877 11.0002L21.9999 0.687917L21.3124 0.000419643L11.0002 10.3127L0.687505 0L7.14634e-06 0.687498L10.3127 11.0002L0 21.3128L0.687498 22.0003L11.0002 11.6877L21.3124 21.9999Z"
          fill={fill}
        />
      </svg>
    </Container>
  );
};

Close.defaultProps = {
  width: 24,
  height: 24,
  fill: theme.mainTitle,
  style: {},
  onClick: () => {},
};

const Container = styled.div<{ width: number; height: number }>`
  cursor: pointer;
  width: ${({ width }) => width + "px"};
  height: ${({ height }) => height + "px"};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export default Close;
