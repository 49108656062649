import styled from "styled-components";
import data from "./data";

const Footer = () => {
  const { address, tel, fax, email, name } = data;
  const engName = name.eng;
  return (
    <FooterContainer>
      <Address>{address}</Address>
      <Ul>
        <li>
          <Label>T</Label>
          <span>{tel}</span>
        </li>
        <li>
          <Label>F</Label>
          <span>{fax}</span>
        </li>
        <li>
          <Label>E</Label>
          <span>{email}</span>
        </li>
      </Ul>
      <CopyrightP>Copyright © {engName} Corp. All rights reserved.</CopyrightP>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  width: 100%;
  padding: 32px 0;
  background: #24252f;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  @media (max-width: ${430}px) {
    align-items: flex-start;
    padding-right: 24px;
    padding-left: 24px;
  }
`;
const Address = styled.span`
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 10px;
`;
const Ul = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  li {
    display: flex;
    align-items: center;
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
      @media (max-width: ${430}px) {
        margin-bottom: 0;
      }
    }
    @media (max-width: ${430}px) {
      margin-bottom: 5px;
    }
  }
  @media (max-width: ${430}px) {
    flex-direction: column;
  }
`;
const Label = styled.span`
  display: inline-flex;
  font-weight: 500;
  margin-right: 8px;
  width: max-content;
  @media (max-width: ${430}px) {
    width: 15px;
  }
`;
const CopyrightP = styled.span`
  font-size: 10px;
  line-height: 12px;
  opacity: 50%;
`;
export default Footer;
