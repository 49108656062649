import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { news } from "./newsData";
import TopBanner from "../components/TopBanner";
import Layout from "../layout";
import cover from "./cover";
import NewsCard from "../components/news/NewsCard";
import MoreButton from "../components/MoreButton";

const LIST_MAX_COUNT = 5;

const News = () => {
  const [maxCount, setMaxCount] = useState(LIST_MAX_COUNT);
  const navigate = useNavigate();

  const clickMoreBtn = () => {
    setMaxCount(maxCount + LIST_MAX_COUNT);
  };

  const moveNewsDetail = (news: any) => {
    navigate(`/news/${news.slug}`, { state: news });
  };
  const { imageUrl, content, title } = cover.news;

  return (
    <Layout>
      <TopBanner imageUrl={imageUrl} content={content} title={title} />
      <Container>
        {news.map((item, index) => {
          if (index < maxCount)
            return (
              <NewsCard
                key={index}
                news={item}
                onClick={() => moveNewsDetail(item)}
              />
            );
          return <></>;
        })}
      </Container>
      <div style={{ marginBottom: "80px" }}>
        {maxCount < news.length && <MoreButton onClick={clickMoreBtn} />}
      </div>
    </Layout>
  );
};

const Container = styled.div`
  margin: 80px 0;
  width: 100%;
  max-width: 1000px;
`;

export default News;
