const about = {
  title: "ABOUT",
  content:
    "고객과 소통하는 것을 중요하게 생각합니다.\n원하는 결과물을 함께 책임감을 가지고 만들어갑니다.",
  imageUrl: "images/banner_about.jpg",
};
const project = {
  title: "PROJECT",
  content: "파이엇 소프트가 참여한\n다양한 프로젝트를 확인할 수 있습니다.",
  imageUrl: "images/banner_project.jpg",
};
const news = {
  title: "UNO NEWS",
  content:
    "새로운 도전과 혁신을 꿈꾸는,\n파이엇 소프트의 발자취를 확인해보세요!",
  imageUrl: "images/banner_news.jpg",
};
const solution = {
  title: "SOLUTION",
  content: "발전된 미래의 솔루션을 위해\n파이엇 소프트가 연구하고 있습니다.",
  imageUrl: "images/banner_solution.jpg",
};

export default { about, project, news, solution };
