import styled from "styled-components";
// import ContactButton from 'src/components/ContactButton'
// import Controller from 'src/components/home/Controller'
import setImageUrl from "../../lib/setImageUrl";
import { response } from "../../styles/theme";
import ContactButton from "../ContactButton";
import { TopBannerContainer } from "../TopBanner";
import Controller from "./Controller";

interface IProps {
  style: any;
  maxLength: number;
  currentId: number;
  nextPage: any;
  prevPage: any;
  data: {
    id: number;
    imageUrl: string;
    title: string;
    content: string;
  };
}

const SlideCard = ({
  data,
  style,
  maxLength,
  currentId,
  nextPage,
  prevPage,
}: IProps) => {
  const { imageUrl, content, title } = data;
  return (
    <Container imageUrl={setImageUrl(imageUrl)} style={{ ...style }}>
      <CenterBoxAlignLeft>
        {title.split("\n").map((str, index, arr) => {
          return (
            <Title
              key={index}
              className={index === arr.length - 1 ? "end" : ""}
            >
              {str}
            </Title>
          );
        })}
        <DescriptionContainer>
          {content.split("\n").map((str, index, arr) => {
            return (
              <Description
                key={index}
                className={index === arr.length - 1 ? "end" : ""}
              >
                {str}
              </Description>
            );
          })}
        </DescriptionContainer>
        <Controller
          currentId={currentId}
          maxLength={maxLength}
          prevPage={prevPage}
          nextPage={nextPage}
        />
        <ContactButton />
      </CenterBoxAlignLeft>
    </Container>
  );
};

const Container = styled(TopBannerContainer)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  background: center / cover no-repeat url(${({ imageUrl }) => imageUrl});
  transition: all 2s linear;
`;
const CenterBox = styled.div`
  max-width: ${response.desktop + "px"};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  position: relative;
`;

const CenterBoxAlignLeft = styled(CenterBox)`
  box-sizing: border-box;

  padding: 0 80px;
  align-items: flex-start;
  @media (max-width: ${response.pad}px) {
    padding: 0 40px;
  }
`;

const Title = styled.h1`
  margin: 0;
  line-height: 150%;
  font-weight: 500;
  font-size: 27px;
  position: relative;
  &.end {
    margin-bottom: 20px;
  }
  @media (max-width: ${response.pad}px) {
    font-size: 24px;
  }
`;
const DescriptionContainer = styled.div`
  height: 130px;
`;
const Description = styled.p`
  font-size: 14px;
  line-height: 150%;
  &.end {
    margin-bottom: 56px;
  }
`;
SlideCard.defaultProps = {
  style: {},
  maxLength: null,
  currentId: null,
  nextPage: () => {},
  prevPage: () => {},
  data: {
    id: null,
    title: "",
    content: "",
    imageUrl: "",
  },
};

export default SlideCard;
