import styled from "styled-components";
import { response } from "../../styles/theme";
import { useRecoilState } from "recoil";
import { modal } from "../../store/store";
import Close from "../svg/Close";
import React, { useCallback, useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import Spinner from "./images/Spinner-5.gif";

interface IValues {
  [index: string]: any;
  name: {
    value: string;
    isEmpty: boolean;
  };
  company: {
    value: string;
    isEmpty: boolean;
  };
  phone: {
    value: string;
    isEmpty: boolean;
  };
  email: {
    value: string;
    isEmpty: boolean;
  };
  project: {
    value: string;
    isEmpty: boolean;
  };
  type: {
    value: string;
    isEmpty: boolean;
  };
  content: {
    value: string;
    isEmpty: boolean;
  };
}

const initialValues = {
  name: {
    value: "",
    isEmpty: false,
  },
  phone: {
    value: "",
    isEmpty: false,
  },
  company: {
    value: "",
    isEmpty: false,
  },
  email: {
    value: "",
    isEmpty: false,
  },
  project: {
    value: "",
    isEmpty: false,
  },
  type: {
    value: "",
    isEmpty: false,
  },
  content: {
    value: "",
    isEmpty: false,
  },
};

const Contact = () => {
  const [isModal, setIsModal] = useRecoilState(modal);
  const [values, setValuse] = useState<IValues>(initialValues);
  const [disabled, setDisabled] = useState(true);
  const [checkBox, setCheckBox] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [sendError, setSendError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = useCallback(
    (e: any) => {
      const id = e.target.name;
      const value = e.target.value;
      let isEmpty = !(value.length > 0);
      if (id === "phone") {
        let regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
        if (regPhone.test(value)) {
          isEmpty = false;
        } else {
          isEmpty = true;
        }
      } else if (id === "email") {
        let regEmail =
          // eslint-disable-next-line no-useless-escape
          /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
        if (regEmail.test(value)) {
          isEmpty = false;
        } else {
          isEmpty = true;
        }
      }
      setValuse({
        ...values,
        [id]: {
          value: value,
          isEmpty: isEmpty,
        },
      });
    },
    [values]
  );

  const resetform = () => {
    setDisabled(true);
    setCheckBox(false);
    setValuse(initialValues);
    setSendError(false);
    setSuccessModal(false);
  };

  const closeModal = () => {
    resetform();
    setIsModal(false);
  };

  useEffect(() => {
    if (!checkBox) {
      setDisabled(true);
      return;
    }
    for (let key in values) {
      if (values[key].isEmpty || values[key].value.length <= 0) {
        setDisabled(true);
        return;
      }
    }
    setDisabled(false);
    return;
  }, [values, checkBox]);

  const sendEmail = () => {
    setIsLoading(true);
    emailjs
      .send(
        "service_72bmgiy",
        "template_9aw1xha",
        {
          name: values.name.value,
          company: values.company.value,
          email: values.email.value,
          phone: values.phone.value,
          project: values.project.value,
          type: values.type.value,
          content: values.content.value,
        },
        "XJXxeOqcid5EMxGqQ"
      )
      .then(() => {
        setIsLoading(false);
        setSuccessModal(true);
      })
      .catch((err) => {
        console.log(err);
        setSendError(true);
        setIsLoading(false);
      });
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (disabled) {
      for (let key in values) {
        if (values[key].value.length === 0) {
          setValuse({
            ...values,
            [key]: {
              value: values[key].value,
              isEmpty: true,
            },
          });
        }
      }
    } else {
      sendEmail();
      return;
    }
  };

  return (
    <>
      {isModal && (
        <Container onClick={closeModal}>
          <ModalConatainer>
            <Modal onClick={(e) => e.stopPropagation()}>
              {isLoading ? (
                <LoadingContainer>
                  <img src={Spinner} alt="" width={"100px"} />
                  <div>잠시만 기다려주세요.</div>
                </LoadingContainer>
              ) : (
                <>
                  <Title>
                    <span>CONTACT</span>
                    <Close onClick={closeModal} />
                  </Title>
                  <InputContainer>
                    <div>
                      <InputBox
                        placeholder="담당자명"
                        type={"text"}
                        name="name"
                        onChange={(e) => handleChange(e)}
                        value={values.name.value}
                      />
                      <Warn isError={values.name.isEmpty}>
                        담당자명 Required field
                      </Warn>
                      <InputBox
                        placeholder="회사명"
                        type={"text"}
                        name="company"
                        onChange={(e) => handleChange(e)}
                        value={values.company.value}
                      />
                      <Warn isError={values.company.isEmpty}>
                        회사명 Required field
                      </Warn>
                      <InputBox
                        placeholder="연락처"
                        type={"text"}
                        name="phone"
                        onChange={(e) => handleChange(e)}
                        value={values.phone.value}
                      />
                      <Warn isError={values.phone.isEmpty}>
                        연락처 Required field
                      </Warn>
                      <InputBox
                        placeholder="이메일"
                        type={"text"}
                        name="email"
                        onChange={(e) => handleChange(e)}
                        value={values.email.value}
                      />
                      <Warn isError={values.email.isEmpty}>
                        이메일 Required field
                      </Warn>
                    </div>
                    <div>
                      <InputBox
                        placeholder="프로젝트명"
                        type={"text"}
                        name="project"
                        onChange={(e) => handleChange(e)}
                        value={values.project.value}
                      />
                      <Warn
                        isError={values.project.isEmpty}
                        style={{ height: "45px" }}
                      >
                        프로젝트명 Required field
                      </Warn>
                      <InputSelectBox
                        name="type"
                        onChange={(e) => handleChange(e)}
                        defaultValue={""}
                      >
                        <option value={""} disabled>
                          프로젝트 유형
                        </option>
                        <option value={"WEB"}>WEB</option>
                        <option value={"AOS"}>AOS</option>
                        <option value={"iOS"}>iOS</option>
                        <option value={"ETC"}>ETC</option>
                      </InputSelectBox>
                      <Warn isError={values.type.isEmpty}>
                        프로젝트 유형 1개 이상 선택
                      </Warn>
                      <Label>상세 문의내역</Label>
                      <TextBox
                        name="content"
                        onChange={(e) => handleChange(e)}
                        value={values.content.value}
                      />
                      <Warn isError={values.content.isEmpty}>
                        상세 문의내역 Required field
                      </Warn>
                    </div>
                  </InputContainer>
                  <ButtonBox>
                    <div />
                    <div>
                      <Checkbox>
                        <CheckboxInput
                          value={checkBox}
                          onClick={() => setCheckBox(!checkBox)}
                        />
                        <span>개인정보 수집 및 이용동의</span>
                      </Checkbox>
                      <Button
                        type="submit"
                        form="contact-form"
                        onClick={handleSubmit}
                        disabled={disabled}
                      >
                        <p>문의하기</p>
                      </Button>
                    </div>
                  </ButtonBox>
                </>
              )}
            </Modal>
          </ModalConatainer>
          {(successModal || sendError) && (
            <NextModal>
              <CenterBox>
                {successModal ? (
                  <>
                    <p className="message">
                      입력하신 문의 내용 등록 완료되었습니다.
                    </p>
                    <p className="message">
                      빠른 시일에 작성하신 연락처를 통해 연락드리겠습니다.
                    </p>
                  </>
                ) : (
                  sendError && (
                    <>
                      <p className="message">문의글 등록에 실패하였습니다.</p>
                      <p
                        className="message"
                        dangerouslySetInnerHTML={{
                          __html:
                            "다시 시도하거나, <strong>dev@uno-solution.com</strong>를 통해 문의바랍니다.",
                        }}
                      />
                    </>
                  )
                )}
                {
                  <ButtonWrap>
                    <Button
                      type="submit"
                      form="contact-form"
                      onClick={
                        sendError ? () => setSendError(false) : closeModal
                      }
                    >
                      <p>닫기</p>
                    </Button>
                  </ButtonWrap>
                }
              </CenterBox>
            </NextModal>
          )}
        </Container>
      )}
    </>
  );
};

const NextModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CenterBox = styled.div`
  box-shadow: ${({ theme }) => theme.bgShadow};
  padding: 50px 50px 35px;
  width: 500px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.inputBorder};
  display: flex;
  flex-direction: column;
  align-items: center;
  p.message {
    line-height: 150%;
    font-size: 14px;
    text-align: center;
    flex: 1;
    color: ${({ theme }) => theme.headerText};
  }
`;

const ButtonWrap = styled.div`
  width: 200px;
  margin-top: 40px;
`;

const Container = styled.div`
  z-index: 10000;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: scroll;
  background-color: rgba(0, 0, 0, 0.6);
`;

const ModalConatainer = styled.div`
  width: 100%;
  overflow: scroll;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 750px;
  min-width: 360px;
  padding: 52px;
  margin: 80px;
  @media (max-width: ${response.pad}px) {
    margin: 0;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
  font-size: 24px;
  line-height: 100%;
  color: ${({ theme }) => theme.headerText};
`;

const InputContainer = styled.div`
  margin: 66px 0;
  display: flex;
  margin: 66px 0;

  & > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
  }

  @media (max-width: ${response.pad}px) {
    flex-direction: column;
  }
`;

const InputBox = styled.input`
  padding: 15px 5px;
  /* margin-bottom: 40px; */
  width: 90%;
  border: 0px;
  border-bottom: 1px solid #dbdbdb;
`;

const InputSelectBox = styled.select`
  padding: 15px 5px;
  /* margin-bottom: 40px; */
  width: 90%;
  font-family: inherit; /* 1 */
  border: 0px;
  border-bottom: 1px solid #dbdbdb;
  & > option:first-child {
    display: none;
  }
  & > option {
    font-family: inherit; /* 1 */
  }
`;

const Label = styled.div`
  font-family: inherit; /* 1 */
  padding: 15px 5px;
  /* margin-bottom: 40px; */
  width: 90%;
  color: gray;
`;

const TextBox = styled.textarea`
  width: 90%;
  outline: none;
  color: ${({ theme }) => theme.headerText};
  border-right: 1px solid ${({ theme }) => theme.inputBorder};
  border-left: 1px solid ${({ theme }) => theme.inputBorder};
  border-bottom: 1px solid ${({ theme }) => theme.inputBorder};
  border-top: 1px solid ${({ theme }) => theme.inputBorder};
  padding: 10px 8px;
  font-size: 14px;
  max-height: 150px;
  line-height: 1.3em;
  min-height: 110px;
  resize: none;
  &::placeholder {
    color: ${({ theme }) => theme.caption};
  }
`;

const Warn = styled.div<{ isError: boolean }>`
  height: 50px;
  margin-top: 10px;
  padding: 0 5px;
  width: 90%;
  font-size: 12px;
  color: ${({ isError }) => (isError ? "red" : "white")};
`;

const ButtonBox = styled.div`
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  .hidden-box {
    visibility: hidden;
  }
  div {
    flex: 1;
  }
  & > div:last-child {
    margin-left: 48px;
  }
  @media (max-width: ${response.pad}px) {
    flex-direction: column;
    & > div:last-child {
      margin-left: 0;
      width: 100%;
    }
  }
`;
const Button = styled.button`
  outline: none;
  cursor: pointer;
  color: #af9883;
  border: 1px solid #af9883;
  padding: 12px 0;
  width: 100%;
  transition: 0.3s;
  p {
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
  }
  &:hover {
    background-color: rgba(175, 152, 131, 0.2);
    transition: 0.3s;
  }
  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.disable};
    border: 1px solid ${({ theme }) => theme.disable};
    background-color: ${({ theme }) => theme.listBorder};
  }
`;
const Checkbox = styled.div`
  font-weight: normal;
  font-size: 13px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.inputHintText};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const CheckboxInput = styled.div<{ value: boolean }>`
  max-width: 20px;
  width: 20px;
  padding: 10px;
  height: 20px;
  margin-right: 10px;
  background-color: ${({ theme, value }) =>
    value ? theme.bgBtnPrimary : "white"};
  border: 1px solid ${({ theme }) => theme.bgBtnPrimary};
  transition: 0.2s;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 700px;
`;

export default Contact;
