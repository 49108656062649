import ReactMarkdown from "react-markdown";
import markdownStyles from "../../styles/markdown-styles.module.css";

const contents = [
  `![짬짬이1](/news/002/zzami_001.jpg)

심부름 매칭 어플리케이션 <짬짬이>를 개발/운영하고있는 파이엇 소프트가2018년 서울 스마트벤처캠퍼스에 4차 산업을 선도할 청년창업팀으로 최종선발되었다고 밝혔다.

스마트벤처캠퍼스는 유망 지식서비스 분야의 청년창업자를 발굴하여 초기 창업 전 단계를 집중 지원하는 중소벤처기업부의 창업지원 사업으로 5개권역(서울·강원/인천·경기/대구·경북/충청·호남/부산·울산·경남)에서 운영되고 있다.

2018 서울 스마트벤처캠퍼스에 선정된 <짬짬이>는 무슨 일이든 요청과 수행이 가능한 심부름 매칭 어플리케이션이다. 고객이 서비스 받고 싶은 일을 작성하면 해당 지역에 등록된 자율근무자들이 견적을 내고, 고객은 견적을 낸 근무자들 중 자신이 원하는 근무자를 선택하여 서비스를 받으면 되는 간편한 방식이다.

![짬짬이2](/news/002/zzami_002.jpg)

짬짬이는 단순심부름부터 전문적인 기술이 필요한 모든 생활 서비스, 녹색알바 등의 서비스 대행까지 모두 요청 가능한데. 이는 짬짬이를 이용하는 자율근무자의 범위가 넓기 때문이다.

청년 실업과, 베이비 부머 은퇴시기와 맞물려진 잉여 인력들, 어디에 소속되지 않고 시간을 효율적으로 쓰고 싶어 하는 자율근무자 및 투잡 직장인이 짬짬이 자율근무자로 소속되어 있기에 짬짬이는 여러 방면의 서비스를 제공할 수 있다.
이렇듯 짬짬이는 바쁜 현대인들의 똑똑하고 합리적인 소비방식과. 자신의 잉여 시간을 소득으로 올리고 싶어하는 현대인들의 수요를 모두 만족시킬 수 있는 어플리케이션이다.
파이엇 소프트 관계자는 “이번 스마트벤처캠퍼스 청년창업팀 선정으로, 현재 운영되고 있는 짬짬이 서비스를 더욱 고도화하고 안정화할 예정이며, 고객들이 더욱 편리하게 일을 요청하고 수행할 수 있도록 할 것”이라고 밝혔다.
`,
  `![짬짬이1](/news/003/zzami_001.jpg)

생활심부름 매칭 서비스 ‘짬짬이’가 ‘띵동’과 제휴 협업 진행 중이라고 밝혔다.
‘띵동’은 ㈜허니비즈에서 운영하는 온디맨드 O2O(Online to Offline) 서비스 애플리케이션으로, 음식 배달뿐 아니라, 고객이 원하는 시간에 무엇이든 즉시 배달하고, 고객의 요구와 상황에 부응하는 다양한 생활편의를 제공하는 온디맨드(On-Demand) 서비스를 제공하고 있다.

㈜파이엇 소프트에서 운영하는 ‘짬짬이’ 역시 생활심부름 매칭 서비스 애플리케이션이다. ‘짬짬이’는 특정한 일을 필요로 하는 사람의 수요와 잉여시간을 활용하여 수익을 내고 싶은 사람의 공급이 맞물려 고객에게 생활서비스와 일자리를 동시에 제공하고 있다.

![짬짬이2](/news/003/zzami_002.jpg)

‘띵동’은 이미 동종업계 선두권을 달리고 있었고, ‘짬짬이’ 역시 작년 정식 출시라는 늦은 출발에 비해 이번 년도 상반기 매출액만 4배 성장하는 등 발전을 보이고 있다.

빠르게 성장하는 두 서비스의 제휴 협업에, 배달에 특화되어 있는 '‘띵동’ 과 가구조립, 집안청소 등의 생활서비스 업무가 많은 '짬짬이‘의 장점을 부각하고 단점을 보완하는 시너지를 보일 전망이다.
짬짬이 관계자는 “두 플랫폼간의 특화된 부분을 분담, 협업, 보완하여 전문성을 살림으로써 더 효율적이고 편리한 생활 서비스를 제공할 수 있을 것“이라고 밝혔다.
`,
  `![짬짬이1](/news/001/zzami_001.jpg)

(주)파이엇 소프트의 생활심부름 매칭 서비스 ‘짬짬이’가 온라인 전자서명 솔루션 ‘공유’와 제휴 협업을 진행한다고 밝혔다. 참고로, 온라인 전자서명 솔루션 ‘공유’는 온라인 계약에 특화되어, 여러 종류의 ‘인증’이 필요한 서류들을 온라인으로 주고받으며, 본인인증을 거쳐 안전한 온라인 전자서명으로 계약을 완료할 수 있는 솔루션이다. 공유’ 솔루션을 통해 손쉽게 근로계약을 진행한다면 최근 근로계약서를 쓰지 않아 임금체불 등의 문제나 근로법 위반으로 피해를 입는 사례를 줄일 수 있을 것으로 보인다.

![짬짬이2](/news/001/zzami_002.jpg)

짬짬이 측 또한, 이번 협업을 통해 단기 일자리 시장의 위험성을 크게 낮출 수 있으리라는 전망이다. 짬짬이에서 매칭되는 초단기 일자리의 계약서가 작성될 시, 일을 요청하는 사람과 일을 수행하는 사람 모두 수행 시 발생할 수 있는 위험요소가 대폭 감소 할 것으로 예상되기 때문이다.
또한 ‘공유’ 솔루션을 이용 중인 HR회사는 호텔, 웨딩, 이벤트 알바 등 공급이 부족하고 급하게 구인이 필요한 경우 짬짬이를 이용하여 빠르게 대량 인원을 구인할 수 있을 것으로 예상된다.

![짬짬이3](/news/001/zzami_003.jpg)

한편, 짬짬이는 기존 APP을 운영하며 발생하던 불편사항을 개선하여 대규모 업데이트를 진행하고 있다고 밝혔다.
기존에 구인/구직용으로 나뉘어져 있던 2개의 APP을 1개로 통합 예정이며, 1:1 매칭에 국한되지 않은 1:多 매칭 등 여러 부분이 개선될 예정이라고 한다. 짬짬이 관계자는 “이번 업데이트와 제휴를 통해 더욱 간편하고 신속하게 안전한 단기 일자리를 구인 구직할 수 있을 것”이라고 밝혔다.
 `,
  `![짬짬이1](/news/004/img_001.jpg)

 [시선뉴스] 현금보다는 카드로 결제하는 요즘 소비자들의 동향에 맞춰 솔루션개발 IT 회사인 파이엇 소프트가 월세를 카드로 자동 납부하는 시스템을 개발해 화제를 모으고 있다. 파이엇 소프트은 부동산 현금시장에서의 월세카드자동결제라는 새로운 결제방식을 고안해, 사용자에게 좀 더 편의를 제공하고자 본 서비스를 개발하게 되었다고 밝혔다.
 
 지난 1일, 파이엇 소프트은 ㈜스테이션3와 다방페이 컨소시엄을 발족하고 업무 협약을 맺은 바 있으며, 현재 다방페이는 출시 후 임차인과 임대인의 월세 관리를 편하게 도와줘 이용자들로부터 긍정적인 반응을 얻고 있다.
 
 다방페이를 이용하게 되면 굳이 월세를 입금하기 위해 은행에 가거나 인터넷으로 공인인증서발급을 받는 등의 복잡한 절차를 거치지 않고. 휴대폰 결제나 공과금을 자동 납부하는 방식과 동일하게 정기결제가 가능하다. 또한, 임차인에게 금융 포인트 적립과 월세 세액 공제 증빙 서류 제공 등의 혜택을 제공할 뿐만 아니라, 임대인에게는 비정기적인 수금으로 인한 문제를 해결해주어 효율적인 임대 및 임대료 관리가 가능하다는 평가를 받고 있다.
 
 파이엇 소프트 관계자는 “부동산 관련 시스템뿐 아니라 모든 솔루션을 개발하는 IT회사로, 앞으로도 솔루션 개발과 홈페이지 구축 등에 박차를 가해 소비자의 니즈에 맞는 다양한 프로그램을 제공, 고객 편의를 높이는 데 앞장 설 것”이라고 전했다.
 `,
];

interface IProps {
  id: number;
}

const Markdown2 = ({ id }: IProps) => {
  return (
    <div className="mx-auto" style={{ color: "red" }}>
      <ReactMarkdown className={markdownStyles["mobile"]}>
        {contents[id]}
      </ReactMarkdown>
    </div>
  );
};

Markdown2.defaultProps = {
  id: 0,
};

export default Markdown2;
