import styled from "styled-components";
import { MarginBottom16, response } from "../../styles/theme";
import setImageUrl from "../../lib/setImageUrl";
import TitleCompo from "./TitleCompo";
import ArrowRight from "../svg/ArrowRight";
import { useNavigate } from "react-router-dom";

export interface News {
  id?: number;
  author?: string;
  coverImage?: string;
  date?: string;
  description?: string;
  email?: string;
  excerpt?: string;
  slug?: string;
  title?: string;
  detail?: string;
}

type category = "AOS" | "iOS" | "WEB";

export interface Project {
  id?: number;
  categorys?: category[];
  coverImage?: string;
  excerpt?: string;
  shortDescription?: string;
  slug?: string;
  title?: string;
  mainImage?: string;
}

export interface Content {
  title: string;
  strongText: string;
  url: string;
}

interface IProps {
  news: News[];
  project: Project[];
  content: Content;
}

const MainContentList = ({ news, content, project }: IProps) => {
  const { title, strongText, url } = content;
  const navigate = useNavigate();
  const movePage = (e: any) => {
    e.preventDefault();
  };
  const moveDetail = (e: any, url: string, item: any) => {
    e.preventDefault();
    if (item.id === undefined) {
      return;
    }
    navigate(url, { state: item });
    return;
  };
  return (
    <Container>
      <Center>
        <CenterBoxWithMB16>
          <TitleCompo align="left" content={{ title, strongText }} url={url} />
          <ArrowRight onClick={movePage} />
        </CenterBoxWithMB16>
        <SlideContainer>
          <BannerContainer>
            {news &&
              news.length > 0 &&
              news.map((item) => (
                <BannerCard
                  onClick={(e) => moveDetail(e, "/news/" + item.slug, item)}
                  key={item.slug}
                  target="_blank"
                >
                  <ImageBox imageUrl={setImageUrl(item.coverImage)} />
                  <p className="title">{item.title}</p>
                  <span className="sub-text">{item.excerpt}</span>
                </BannerCard>
              ))}
            {project &&
              project.length > 0 &&
              project
                .filter((el) => el.title !== "짬짬이")
                .map((item) => (
                  <BannerCard
                    onClick={(e) =>
                      moveDetail(e, "/project/" + item.slug, item)
                    }
                    key={item.slug}
                    target="_blank"
                  >
                    <ImageBox imageUrl={setImageUrl(item.coverImage)} />
                    <span className="short-desc">{item.shortDescription}</span>
                    <p className="title">{item.title}</p>
                  </BannerCard>
                ))}
          </BannerContainer>
        </SlideContainer>
      </Center>
    </Container>
  );
};

MainContentList.defaultProps = {
  news: null,
  project: null,
  content: {
    title: "",
    strongText: "",
    url: "",
  },
  movePath: "/",
};

const CenterBoxWithMB16 = styled(MarginBottom16)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${response.pad}px) {
    margin-right: 24px;
    margin-left: 24px;
  }
  @media (min-width: ${response.pad +
    1}px) and (max-width: ${response.desktop}px) {
    margin-right: 24px;
    margin-left: 24px;
  }
`;
const Container = styled.div`
  background-color: #fbfbfb;
  width: 100%;
  display: flex;
  justify-content: center;
`;
const Center = styled.div`
  margin: 80px 0;
  width: 100%;
  max-width: ${(response.desktop / 100) * 70 + "px"};
  @media (max-width: ${response.pad}px) {
    margin-top: 60px;
    margin-bottom: 30px;
  }
  @media (min-width: ${response.pad +
    1}px) and (max-width: ${response.desktop}px) {
    margin: 80px 24px;
    min-width: ${response.pad + "px"};
  }
`;
const BannerCard = styled.a`
  text-decoration: none;
  display: block;
  cursor: pointer;
  overflow: hidden;
  letter-spacing: -0.03em;
  line-height: 150%;
  .short-desc {
    font-size: 14px;
    line-height: 1.4em;
    color: #757575;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .sub-text {
    font-size: 14px;
    color: #757575;
    line-height: 1.4em;
    max-height: 2.8em;
    font-weight: 300;
    /* font-weight: normal; */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow-y: hidden;
  }
  .title {
    font-weight: 500;
    font-size: 16px;
    color: #24252f;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 12px;
  }
  @media (max-width: ${response.pad}px) {
    width: 240px;
    &:last-child {
      width: 264px;
      padding-right: 24px;
    }
  }
`;
const ImageBox = styled.div<{ imageUrl: string }>`
  background-color: #fff;
  border-radius: 10px;
  height: 120px;
  background: no-repeat center / cover url(${({ imageUrl }) => imageUrl});
  margin-bottom: 12px;
`;
const SlideContainer = styled.div`
  @media (max-width: ${response.pad}px) {
    overflow-y: hidden;
    overflow-x: scroll;
    width: 100%;
    height: fit-content;
    padding-left: 24px;
  }
`;
const BannerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 72px;
  column-gap: 20px;
  @media (max-width: ${response.pad}px) {
    width: max-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: scroll;
  }
  @media (min-width: ${response.pad +
    1}px) and (max-width: ${response.desktop}px) {
    min-width: ${response.pad + "px"};
    padding: 0 24px;
  }
`;
export default MainContentList;
