import Company from "../components/about/Company";
import History from "../components/about/History";
import UnoMap from "../components/about/UnoMap";
import TopBanner from "../components/TopBanner";
import Layout from "../layout";
import cover from "./cover";

const About = () => {
  const { imageUrl, content, title } = cover.about;

  return (
    <Layout>
      <TopBanner imageUrl={imageUrl} content={content} title={title} />
      <Company />
      <History />
      <UnoMap />
    </Layout>
  );
};

export default About;
