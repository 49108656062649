import styled from "styled-components";
import { Project } from "../home/MainContentList";
import { useLocation } from "react-router-dom";
import { response } from "../../styles/theme";
import setImageUrl, { getMobileImageUrl } from "../../lib/setImageUrl";
import { bigWidthThanPadWidth } from "../../lib/replaceJsx";
import { width } from "../../store/store";
import { useRecoilValue } from "recoil";
import ProMarkdown from "./ProMarkdown";
import Layout from "../../layout";

const ProjectDetail = () => {
  const { state } = useLocation() as unknown as {
    state: Project;
  };
  const { mainImage, title, excerpt, id } = state;
  const innerWidth = useRecoilValue(width);
  return (
    <Layout>
      <Container>
        <img
          src={
            innerWidth && innerWidth < (response.pad / 3) * 2
              ? setImageUrl(getMobileImageUrl(mainImage || ""))
              : setImageUrl(mainImage)
          }
          alt=""
        />
        <Title>{title}</Title>
        <Excerpt>
          {bigWidthThanPadWidth(excerpt || "", innerWidth || response.pad)}
        </Excerpt>
        <ProMarkdown id={id} />
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  color: ${({ theme }) => theme.mainTitle};
  margin: 80px 0;
  padding: 0 64px;
  /* padding-top: 30px; */
  box-sizing: border-box;
  width: 100%;
  max-width: ${response.desktop - 200 + "px"};
  img {
    width: 100%;
  }
  @media (max-width: ${response.pad}px) {
    max-width: ${response.pad + "px"};
    padding: 0;
  }
  @media (min-width: ${response.pad +
    1}px) and (max-width: ${response.desktop}px) {
    padding-right: 25px;
    padding-left: 25px;
  }
`;
const Excerpt = styled.div`
  color: ${({ theme }) => theme.subText};
  font-weight: 300;
  font-size: 16px;
  line-height: 140%;
  margin-top: 10px;
  margin-bottom: 56px;
  @media (max-width: ${response.pad}px) {
    padding-right: 25px;
    padding-left: 25px;
  }
  @media (min-width: ${response.pad +
    1}px) and (max-width: ${response.desktop}px) {
    padding-right: 25px;
    padding-left: 25px;
  }
`;
const Title = styled.h2`
  margin-top: 56px;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 36px;
  line-height: 120%;
  @media (max-width: ${response.pad}px) {
    font-size: 24px;
    padding-right: 25px;
    padding-left: 25px;
  }
  @media (min-width: ${response.pad +
    1}px) and (max-width: ${response.desktop}px) {
    padding-right: 25px;
    padding-left: 25px;
  }
`;

export default ProjectDetail;
