import { atom } from "recoil";

export const modal = atom<boolean>({
  key: "modal",
  default: false,
});

export const width = atom<number | null>({
  key: "width",
  default: null,
});
