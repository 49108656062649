import styled, { css } from "styled-components";
import { useNavigate } from "react-router-dom";

interface IProps {
  width: number;
  height: number;
  themeMode: "dark" | "light";
}

const Logo = ({ themeMode, width, height }: IProps) => {
  const navigate = useNavigate();
  const moveHome = () => {
    navigate("/");
  };
  return (
    <Container onClick={moveHome}>
      <Image width={width} height={height}>
        <Piot>Piot</Piot>
        <Soft>soft</Soft>
      </Image>
    </Container>
  );
};

const Container = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Image = styled.div<{ width: number; height: number }>`
  ${({ width }) =>
    css`
      width: ${width + "px"};
    `}
  ${({ height }) =>
    css`
      height: ${height + "px"};
    `}
    display: flex;
`;
const Piot = styled.div`
  flex: 1;
  font-size: 32px;
`;
const Soft = styled.div`
  flex: 1;
  font-size: 15px;
  margin-left: 5px;
`;
Logo.defaultProps = {
  themeMode: "dark", // dark=흰글씨, light=검정글씨
  width: 100,
  height: 100,
};

export default Logo;
