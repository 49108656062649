import styled from "styled-components";
import TitleCompo from "./TitleCompo";
import theme, { MarginBottom16, response } from "../../styles/theme";
import setImageUrl from "../../lib/setImageUrl";
import cover from "../../pages/cover";

export interface Content {
  id: number;
  subText: string;
  siteName: string;
  url: string;
}

export interface Contents {
  title: string;
  strongText: string;
  contents: Content[];
}

interface IProps {
  content: Contents;
}

const LinkBanner = ({ content }: IProps) => {
  const { title, strongText, contents } = content;
  const openTap = (e: React.MouseEvent<HTMLDivElement>, url: string) => {
    e.preventDefault();
    window.open(url);
  };
  return (
    <Container>
      <Center>
        <MarginBottom16>
          <TitleCompo
            align="left"
            size="small"
            content={{ title, strongText }}
          />
        </MarginBottom16>
        <BannerContainer>
          {contents.map((link) => (
            <BannerCard onClick={(e) => openTap(e, link.url)} key={link.id}>
              <span className="sub-text">{link.subText}</span>
              <br />
              <span className="site-name">{link.siteName}</span>
            </BannerCard>
          ))}
        </BannerContainer>
      </Center>
      <ImgPreLoader
        urls={[
          setImageUrl(cover.about.imageUrl),
          setImageUrl(cover.project.imageUrl),
        ]}
      />
    </Container>
  );
};

LinkBanner.defaultProps = {
  content: {
    title: "",
    strongText: "",
    contents: [],
  },
};

const Container = styled.div`
  background-color: #fbfbfb;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: visible;
`;
const Center = styled.div`
  width: 100%;
  max-width: ${(response.desktop / 100) * 70 + "px"};
  margin: 80px 0;
  padding: 0;
  @media (max-width: ${response.pad}px) {
    margin-top: 60px;
    margin-bottom: 30px;
    padding: 0 24px;
  }
  @media (min-width: ${response.pad +
    1}px) and (max-width: ${response.desktop}px) {
    padding: 0 24px;
    min-width: ${response.pad + "px"};
  }
`;
const BannerCard = styled.div`
  text-decoration: none;
  cursor: pointer;
  /* width: 240px; */
  text-align: center;
  padding: 20px 0;
  background-color: #fff;
  box-shadow: ${theme.bgShadow};
  border-radius: 10px;
  letter-spacing: -0.03em;
  line-height: 150%;
  .sub-text {
    font-size: 14px;
    color: #757575;
  }
  .site-name {
    font-weight: 500;
    font-size: 16px;
    color: #24252f;
  }
`;
const BannerContainer = styled.div`
  overflow: visible;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;
  @media (max-width: ${response.pad / 2}px) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: ${response.pad / 2 +
    1}px) and (max-width: ${response.pad}px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: ${response.pad +
    1}px) and (max-width: ${response.desktop}px) {
  }
  @media (min-width: ${response.desktop + 1}px) {
  }
`;

const ImgPreLoader = styled.div<{ urls: string[] }>`
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;
  overflow: hidden;

  content: ${({ urls }) => `
    url(${urls[0]}) url(${urls[1]});
  `};
`;
export default LinkBanner;
