import { useEffect, useState } from "react";
import styled from "styled-components";
import setImageUrl from "../../lib/setImageUrl";
import { TopBannerContainer } from "../TopBanner";
import SlideCard from "./SlideCard";

interface ISlide {
  id: number;
  imageUrl: string;
  title: string;
  content: string;
}

const MainSlide = ({ slide }: { slide: ISlide[] }) => {
  const [currentId, setCurrentId] = useState(0);
  const getNextPageIndex = () =>
    currentId < slide.length - 1 ? currentId + 1 : 0;
  const getPrevPageIndex = () =>
    currentId === 0 ? slide.length - 1 : currentId - 1;
  const nextPage = () => setCurrentId(getNextPageIndex());
  const prevPage = () => setCurrentId(getPrevPageIndex());

  return (
    <Container imageUrl={""}>
      {slide.map((item, index, arr) => {
        return (
          item.id === currentId && (
            <SlideCard
              key={item.id}
              data={item}
              maxLength={arr.length}
              currentId={currentId}
              prevPage={prevPage}
              nextPage={nextPage}
            />
          )
        );
      })}
      <ImagePreLoader
        urls={slide.slice(1).map((el) => setImageUrl(el.imageUrl))}
      />
    </Container>
  );
};

const Container = styled(TopBannerContainer)`
  position: relative;
  width: 100%;
`;

const ImagePreLoader = styled.div<{ urls: string[] }>`
  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
  z-index: -1;

  content: ${({ urls }) => `
    url(${urls[0]}) url(${urls[1]});
  `};
`;

MainSlide.defaultProps = {
  slide: [],
};

export default MainSlide;
