import styled from "styled-components";
import historys from "./historys";
import { response } from "../../styles/theme";

const History = () => {
  const { history } = historys;
  const years = Object.keys(history);

  return (
    <Container>
      {years.map((year) => (
        <Row key={year}>
          <Year className="year">{year}</Year>
          <CenterBar className="center-bar">
            <Round />
            <Stick />
          </CenterBar>
          <TextBox className="text-box">
            {history[year].map((item, index) => (
              <div key={index}>
                <Month>{item.month}</Month>
                <Title>{item.title}</Title>
              </div>
            ))}
          </TextBox>
        </Row>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  overflow: visible;
  @media (max-width: ${response.pad}px) {
    margin-top: 40px;
    position: relative;
    left: 24px;
  }
`;
const Row = styled.div`
  position: relative;
  display: flex;
  overflow: visible;
`;
const Year = styled.div`
  display: flex;
  align-items: center;
  min-width: 50px;
  font-weight: 500;
  font-size: 20px;
  @media (max-width: ${response.pad}px) {
    order: 2;
    align-items: flex-start;
    position: relative;
    top: -4px;
  }
`;
const CenterBar = styled.div`
  min-width: 11px;
  width: 11px;
  display: flex;
  justify-content: center;
  margin: 0 56px;
  position: relative;
  @media (max-width: ${response.pad}px) {
    order: 1;
    margin: 0;
    margin-right: 16px;
  }
`;
const Round = styled.div`
  width: 11px;
  height: 11px;
  border-radius: 11px;
  background-color: #efd3a5;
  position: absolute;
  top: 0;
`;
const Stick = styled.div`
  width: 1px;
  background-color: #efd3a5;
  position: absolute;
  top: 0;
  height: 100%;
`;
const TextBox = styled.div`
  flex-grow: 1;
  margin: 30px 0;
  & > div {
    display: flex;
    align-items: flex-start;
    color: red;
    padding-bottom: 12px;
    &:last-child {
      padding-bottom: 0;
    }
  }
  @media (max-width: ${response.pad}px) {
    order: 3;
    margin-top: 40px;
    margin-bottom: 50px;
    position: relative;
    left: -40px;
  }
`;
const Month = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.caption};
  min-width: max-content;
  font-weight: 300;
  font-size: 16px;
`;
const Title = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.listTitle};
  font-weight: 500;
  font-size: 15px;
  margin-left: 16px;
`;

export default History;
