import styled from "styled-components";
import setImageUrl from "../../lib/setImageUrl";
import { response } from "../../styles/theme";
import { googleMapUrl, address } from "../data";

const UnoMap = () => {
  const openGoogleMap = () => {
    window.open(googleMapUrl, "_blank");
  };
  return (
    <Container>
      <MapContainer src={setImageUrl("images/new_uno_map.svg")} />
      <p style={{ lineHeight: "1.5em", fontSize: "20px", fontWeight: 200 }}>
        LOCATION
      </p>
      <p style={{ lineHeight: "1.5em", fontSize: "14px" }}>{address}</p>
      <Link onClick={openGoogleMap}>구글맵에서 보기</Link>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: cetner;
  align-items: center;
  color: ${({ theme }) => theme.mainTitle};
  padding: 80px 0;
  @media (max-width: ${response.pad}px) {
    padding: 80px 0 0;
  }
`;
const MapContainer = styled.div<{ src: string }>`
  /* border: 1px solid tomato; */
  background: center / contain no-repeat url(${({ src }) => src});
  width: 100%;
  height: 490px;
  margin-bottom: 40px;
  @media (max-width: ${(response.pad / 3) * 2}px) {
    margin: 0 24px;
    margin-bottom: 20px;
    height: ${response.pad / 2 + "px"};
  }
`;
const Link = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.caption};
  font-weight: 300;
  font-size: 14px;
  text-decoration: none;
  border-bottom: 1px solid ${({ theme }) => theme.caption};
  margin: 20px 0 80px;
  transition: 0.5s;
  &:hover {
    color: ${({ theme }) => theme.mainTitle};
    transition: 0.5s;
  }
`;
export default UnoMap;
