export default {
  banner: [
    {
      id: 0,
      title: "발전된 미래의 솔루션을\n위해 연구하고 있습니다",
      content:
        "발전된 미래의 솔루션을 위해\n새로운 시각으로 서비스를 바라보며,\n사용자와 제공자 모두 편리할 수 있도록 제작합니다.",
      imageUrl: "images/img_main_slide-1.jpg",
    },
    {
      id: 1,
      title: "보다 신속하고 안전한\n서비스를 제공합니다",
      content:
        "온라인으로 주고받은 문서의 본인인증과\n전자서명으로 보다 안전하게 문서를 공유하며\n모바일 기기를 활동한 FIDO인증으로\n빠르고 안전하게문서전자인증 서비스를 제공합니다.",
      imageUrl: "images/img_main_slide-2.jpg",
    },
    {
      id: 2,
      title: "당신께 꼭 맞는 인력 매칭\n알고리즘을 연구합니다.",
      content:
        "사용자의 패턴을 분석하여\n적절한 업무를 추천하고,\n기업에 필요한 인력을 먼저 추천해주는\n'매칭' 알고리즘을 구축합니다.",
      imageUrl: "images/img_main_slide-3.jpg",
    },
  ],
  greetings: {
    title: "안녕하세요,\n파이엇 소프트 입니다.",
    strongText: "파이엇 소프트",
    content:
      "편리하고 감각적인 UXUI 설계와 안정적인 유지보수로 고객님이 보다 쉽고 체계적으로 관리할 수 있도록 편리한 Web과 App 솔루션을 제작해드립니다.",
  },
  // 프로젝트 목록/ 뉴스 목록은 해당 컨텐츠에서 가져와서 사용하기
  project: {
    title: "파이엇 소프트에서 진행한,\n프로젝트 입니다.",
    strongText: "프로젝트",
    url: "/project",
    // content: projects,
  },
  news: {
    title: "새로운 도전을 하는,\n파이엇 소프트 뉴스입니다.",
    strongText: "파이엇 소프트 뉴스",
    url: "/news",
    // content: news,
  },
  links: {
    title: "파이엇 소프트와 함께 할\nPiot soft팀의 동료를 찾습니다",
    strongText: "Piot soft팀의 동료",
    contents: [
      {
        id: 0,
        subText: "채용 공고 모두보기!",
        siteName: "WANTED",
        url: "https://www.wanted.co.kr/company/38228",
      },
      {
        id: 1,
        subText: "입사 정보가 궁금하다면?",
        siteName: "NOTION",
        url: "https://www.notion.so/UNO-TEAM-7a02e092cdb240ff967583e82a5ecbfb",
      },
      {
        id: 2,
        subText: "지원하고 싶다면?",
        siteName: "Rocket Punch",
        url: "https://www.rocketpunch.com/companies/piotsoft/jobs",
      },
    ],
  },
};
