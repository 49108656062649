import styled from "styled-components";
import arrowLeft from "./images/ic_arrow-left.png";

interface IProps {
  width: number;
  height: number;
  style: any;
  onClick: any;
}

const ArrowLeft = ({ width, height, style, onClick }: IProps) => {
  return (
    <Container width={width} height={height} style={style} onClick={onClick}>
      <img src={arrowLeft} alt="" width={"30px"} />
    </Container>
  );
};

ArrowLeft.defaultProps = {
  width: 24,
  height: 24,
  style: {},
  onClick: () => {},
};

const Container = styled.div<{ width: number; height: number }>`
  cursor: pointer;
  padding: 4px;
  width: ${({ width }) => width + "px"};
  height: ${({ height }) => height + "px"};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export default ArrowLeft;
