import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { replaceBrTag } from "../../lib/replaceJsx";
import setImageUrl from "../../lib/setImageUrl";
import { response } from "../../styles/theme";
import uno from "../data";

const Company = () => {
  const navigate = useNavigate();
  const moveHome = () => {
    navigate("/");
  };
  const { logo, name, slogun } = uno;
  return (
    <Container>
      <LogoContainer onClick={moveHome}>
        <Image>
          <Piot>Piot</Piot>
          <Soft>soft</Soft>
        </Image>
      </LogoContainer>
      <Info>
        <div style={{ marginBottom: "8px" }}>
          <Name className="ko">{name.kor}</Name>
          <Name className="en">{name.eng}</Name>
        </div>
        <Slogun>
          {replaceBrTag(slogun).filter((str, index) => (
            <div key={index}>{str}</div>
          ))}
        </Slogun>
      </Info>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 100px 0;
  @media (max-width: ${response.pad}px) {
    margin: 50px 24px;
    flex-direction: column;
  }
`;

const LogoContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Image = styled.div`
  max-width: 306px;
  min-width: 200px;
  height: 102px;
  display: flex;
`;
const Piot = styled.div`
  flex: 1;
  font-size: 50px;
`;
const Soft = styled.div`
  flex: 1;
  font-size: 20px;
  margin-left: 5px;
`;
const Info = styled.div`
  margin-left: 40px;
  line-height: 150%;
  display: flex;
  flex-direction: column;
  align-content: center;
  @media (max-width: ${response.pad}px) {
    margin-left: 0;
    margin-top: 24px;
    flex-direction: column;
  }
`;
const Name = styled.span`
  display: inline-block;
  font-size: 20px;
  &.ko {
    font-weight: 500;
    margin-right: 10px;
    color: ${({ theme }) => theme.mainTitle};
  }
  &.en {
    font-weight: 300;
    color: ${({ theme }) => theme.caption};
  }
`;
const Slogun = styled.span`
  color: ${({ theme }) => theme.headerText};
  display: inline-block;
  font-size: 14px;
  @media (max-width: ${response.pad}px) {
    text-align: center;
    margin-left: 0;
    flex-direction: column;
  }
`;

export default Company;
