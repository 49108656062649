import styled from "styled-components";

const MoreButton = ({ style, onClick }: any) => {
  return (
    <Button style={style} onClick={onClick}>
      <p>더보기</p>
    </Button>
  );
};

const Button = styled.div`
  cursor: pointer;
  color: #af9883;
  border: 1px solid #af9883;
  padding: 12px 0;
  width: 280px;
  transition: 1s;
  p {
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
  }
  &:hover {
    background-color: rgba(175, 152, 131, 0.2);
    transition: 1s;
  }
`;

MoreButton.defaultProps = {
  style: {},
  onClick: () => {},
};

export default MoreButton;
