import styled from "styled-components";
import { response } from "../../styles/theme";
import { bigWidthThanPadWidth } from "../../lib/replaceJsx";
import setImageUrl, { getMobileImageUrl } from "../../lib/setImageUrl";

interface IProps {
  data: {
    id: number;
    title: string;
    description: string;
    content: {
      id: number;
      title: string;
      description: string;
      imageUrl: string;
    }[];
  };
}

const SolutionCard = ({ data }: IProps) => {
  const { title, description, content } = data;

  return (
    <Container>
      <CenterContainer>
        <HeaderContainer>
          <Title>{title}</Title>
          <Description>{bigWidthThanPadWidth(description)}</Description>
        </HeaderContainer>
        {content.map((item, index) => {
          return (
            <ContentBox key={index}>
              <SubTitle>{item.title}</SubTitle>
              <SubDescription>
                {bigWidthThanPadWidth(item.description)}
              </SubDescription>
              <Img
                src={setImageUrl(item.imageUrl)}
                // TODO : setMobileImageUrl
                alt=""
              />
            </ContentBox>
          );
        })}
      </CenterContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.mainTitle};
  display: flex;
  flex-direction: column;
  align-items: center;
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.bgSub};
  }
`;
const CenterContainer = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.response.pad + "px"};
  padding: 0 25px;
`;
const HeaderContainer = styled.div`
  margin-top: 80px;
`;
const Title = styled.h2`
  margin: 0;
  color: #6b6b6b;
  font-weight: 300;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
`;
const Description = styled.p`
  font-size: 16px;
  line-height: 150%;
  margin: 16px 0;
  text-align: center;
`;
const SubTitle = styled.h3`
  margin: 0;
  margin-bottom: 8px;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
`;
const SubDescription = styled.p`
  margin: 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 140%;
  color: #777777;
`;
const Img = styled.img`
  display: block;
  margin-top: 48px;
  width: 100%;
`;
const ContentBox = styled.div`
  padding: 48px 0;
  border-top: 1px solid ${({ theme }) => theme.listBorder};
  &:last-child {
    /* border-bottom: 1px solid #ddd;*/
    margin-bottom: 40px;
  }
`;

SolutionCard.defaultProps = {
  data: { title: "", description: "", content: [] },
};

export default SolutionCard;
