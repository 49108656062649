import styled from "styled-components";
import PropTypes from "prop-types";
import { MarginBottom16, response, SubText } from "../../styles/theme";
import TitleCompo from "./TitleCompo";

interface IProps {
  greetings: {
    title: string;
    strongText: string;
    content: string;
  };
}

const Greetings = ({ greetings }: IProps) => {
  return (
    <Container>
      <Center>
        <MarginBottom16>
          <TitleCompo content={greetings} size={"large"} />
        </MarginBottom16>
        <SubText>{greetings.content}</SubText>
      </Center>
    </Container>
  );
};
Greetings.defaultProps = {
  greetings: {
    title: "",
    strongText: "",
    content: "",
  },
};
Greetings.propTypes = {
  greetings: PropTypes.shape({
    title: PropTypes.string,
    strongText: PropTypes.string,
    content: PropTypes.string,
  }),
};

const Container = styled.div`
  width: 100%;
  padding: 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #24252f;
  @media (max-width: ${response.pad}px) {
    padding: 64px 24px;
  }
`;
const Center = styled.div`
  width: 350px;
`;
export default Greetings;
