import { useEffect, useState } from "react";
import Greetings from "../components/home/Greetings";
import MainContentList from "../components/home/MainContentList";
import MainSlide from "../components/home/MainSlide";
import Layout from "../layout";
import data from "./data";
import { project } from "./projectData";
import { news } from "./newsData";
import LinkBanner from "../components/home/LinkBanner";

const Home = () => {
  const [banner, setBanner] = useState(
    data.banner.map((item) => ({ ...item }))
  );
  const { greetings, links, project: homeProject, news: homeNews } = data;

  useEffect(() => {
    setBanner(data.banner.map((item) => ({ ...item })));
  }, []);

  return (
    <Layout>
      <MainSlide slide={banner} />
      <Greetings greetings={greetings} />
      <MainContentList
        movePath={"/project"}
        project={project}
        content={homeProject}
      />
      {/* <MainContentList movePath={"/news"} news={news} content={homeNews} /> */}
      <LinkBanner content={links} />
    </Layout>
  );
};

export default Home;
