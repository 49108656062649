import { News } from "../components/home/MainContentList";

export const news: News[] = [
  {
    id: 0,
    title: "파이엇 소프트, 2018 서울 스마트벤처캠퍼스 청년창업팀으로 최종선발",
    slug: "002_zzamzzami",
    excerpt:
      "심부름 매칭 어플리케이션 <짬짬이>를 개발/운영하고있는 파이엇 소프트가2018년 서울 스마트벤처캠퍼스에 4차 산업을 선도할 청년창업팀으로 최종선발되었다고 밝혔다.",
    coverImage: "news/002/thum.jpg",
    description: "<저작권자 © 데일리시큐 무단전재 및 재배포 금지>",
    author: "데일리시큐",
    date: "2021-03-31 13:00:00",
    email: "https://www.dailysecu.com",
  },
  {
    id: 1,
    title: "커져가는 O2O 플랫폼 시장, ‘띵동’, ‘짬짬이’ 제휴 협업 진행 중",
    excerpt:
      "생활심부름 매칭 서비스 ‘짬짬이’가 ‘띵동’과 제휴 협업 진행 중이라고 밝혔다. ‘띵동’은 ㈜허니비즈에서 운영하는 온디맨드 O2O(Online to Offline) 서비스 애플리케이션으로, 음식 배달뿐 아니라, 고객이 원하는 시간에 무엇이든 즉시 배달하고, 고객의 요구와 상황에 부응하는 다양한 생활편의를 제공하는 온디맨드(On-Demand) 서비스를 제공하고 있다.",
    coverImage: "news/003/thum.jpg",
    slug: "003_zzamzzami",
    author: "데일리시큐",
    email: "https://www.dailysecu.com",
    description: "<저작권자 © 데일리시큐 무단전재 및 재배포 금지>",
    date: "2018-08-22 13:00:00",
  },
  {
    id: 2,
    title:
      "초단기 일자리 매칭 APP ‘짬짬이’, 온라인 전자서명 솔루션 ‘공유’와 협업",
    excerpt:
      "(주)파이엇 소프트의 생활심부름 매칭 서비스 ‘짬짬이’가 온라인 전자서명 솔루션 ‘공유’와 제휴 협업을 진행한다고 밝혔다. 참고로, 온라인 전자서명 솔루션 ‘공유’는 온라인 계약에 특화되어, 여러 종류의 ‘인증’이 필요한 서류들을 온라인으로 주고받으며, 본인인증을 거쳐 안전한 온라인 전자서명으로 계약을 완료할 수 있는 솔루션이다. 공유’ 솔루션을 통해 손쉽게 근로계약을 진행한다면 최근 근로계약서를 쓰지 않아 임금체불 등의 문제나 근로법 위반으로 피해를 입는 사례를 줄일 수 있을 것으로 보인다.",
    coverImage: "news/001/thum.jpg",
    slug: "001_zzamzzami",
    date: "2018-05-17 13:00:00",
    author: "소믈리에타임즈 한상만 기자",
    email: "stpress@sommeliertimes.com",
    description: "<저작권자 © 소믈리에타임즈, 무단 전재 및 재배포 금지>",
  },
  {
    id: 3,
    title: "파이엇 소프트 “이제 월세도 카드로 납부하세요”",
    excerpt:
      "[시선뉴스] 현금보다는 카드로 결제하는 요즘 소비자들의 동향에 맞춰 솔루션개발 IT 회사인 파이엇 소프트가 월세를 카드로 자동 납부하는 시스템을 개발해 화제를 모으고 있다. 파이엇 소프트은 부동산 현금시장에서의 월세카드자동결제라는 새로운 결제방식을 고안해, 사용자에게 좀 더 편의를 제공하고자 본 서비스를 개발하게 되었다고 밝혔다.",
    coverImage: "news/001/thum.jpg",
    slug: "004_dabangpay",
    date: "2016-12-30 13:00:00",
    author: "시선뉴스",
    email: "http://www.sisunnews.co.kr",
    description:
      "<저작권자 © 시선뉴스 무단전재 및 재배포 금지> http://www.sisunnews.co.kr/news/articleView.html?idxno=46401",
  },
];
