import { Project } from "../components/home/MainContentList";

export const project: Project[] = [
  {
    id: 6,
    title: "피터팬의 좋은방 구하기",
    shortDescription: "무보증방 서비스",
    coverImage: "project/peterpanz/img_peterpanz.svg",
    slug: "007_peterpanz",
    mainImage: "project/peterpanz/img_peterpanz.svg",
    categorys: ["WEB"],
    excerpt:
      "원하는 조건의 무보증방을 찾고, <br/>계약할 수 있는 서비스 입니다.",
  },
  {
    id: 0,
    coverImage: "project/bf_membership/img_bfmembership.jpg",
    shortDescription: "멤버십 APP 서비스",
    slug: "001_bf_membership",
    title: "바디프랜드 멤버쉽",
    excerpt:
      "바디프랜드 고객들이 편리하게 바디프랜드 상품을 구매하고,<br/>관리할 수 있는 프리미엄 서비스 입니다.",
    categorys: ["AOS", "iOS"],
    mainImage: "project/bf_membership/img_detail_membership_1.jpg",
  },
  {
    id: 1,
    coverImage: "project/bf_lounge/img_launge.jpg",
    shortDescription: "공유 안마 렌탈 APP 서비스",
    slug: "002_bf_lounge",
    title: "바디프랜드 라운지",
    categorys: ["AOS", "iOS"],
    excerpt:
      "안마의자를 활용한 휴식공간인 바디프랜드 라운지를 찾고<br/>비대면으로 결제하여 이용할 수 있습니다.",
    mainImage: "project/bf_lounge/img_detail_lounge-1.jpg",
  },
  {
    id: 2,
    coverImage: "project/uno_zzami/img_zzam.jpg",
    shortDescription: "신개념 일자리 창출 플랫폼",
    slug: "003_uno_zzami",
    title: "짬짬이",
    excerpt:
      "짬짬이 전문인력이 힘든일상에 지친 당신을 위해<br/>소소한 일을 대신 수행해드립니다.",
    categorys: ["AOS", "iOS"],
    mainImage: "project/uno_zzami/img_detail_zzam-1.jpg",
  },
  {
    id: 3,
    title: "플로우진",
    shortDescription: "모든 패션, 모든 체형, 모든 여성들을 위한",
    coverImage: "project/flowjeans/img_flowjeans.jpg",
    slug: "004_flow_jeans",
    excerpt:
      "편안함과 패션 두가지를 모두 고려하여 만든<br/>패션 프랜드 쇼핑 웹사이트 입니다.",
    categorys: ["AOS", "iOS", "WEB"],
    mainImage: "project/flowjeans/img_detail_frow-1.jpg",
  },
  {
    id: 4,
    title: "As-is",
    shortDescription: "디자이너 상품 타임 세일 서비스",
    coverImage: "project/asis/img_asis.jpg",
    slug: "005_asis",
    excerpt:
      "누군가에게 필요한 옷의 가치를 높이는,<br/>유익한 소비문화를 만드는 쇼핑콘텐츠 입니다.",
    categorys: ["AOS", "iOS", "WEB"],
    mainImage: "project/asis/img_detail_asis-1.jpg",
  },
  {
    id: 5,
    title: "타임픽",
    shortDescription: "스튜디어 검색 및 간편 예약",
    coverImage: "project/timepick/img_timepick.jpg",
    slug: "006_timepick",
    excerpt:
      "타임픽을 통해 내가 원하는 멋진 스튜디오를 찾고<br/>예약할 수 있습니다.",
    categorys: ["AOS", "iOS", "WEB"],
    mainImage: "project/timepick/img_detail_timepick-1.jpg",
  },
  // {
  //   title: "다방페이",
  //   shortDescription: "월세자동 결제 시스템",
  //   coverImage: "project/dabang/img_dabang.svg",
  //   slug: "008_dabangpay",
  // },
  // {
  //   title: "이케아X짬짬이뚝닥",
  //   shortDescription: "IKEA 조립기사 예약서비스",
  //   coverImage: "project/ikea/img_ikea.svg",
  //   slug: "009_ikea",
  // },
];
