import styled from "styled-components";
import setImageUrl from "../../lib/setImageUrl";
import { response } from "../../styles/theme";
import { News } from "../home/MainContentList";

interface IProps {
  onClick: any;
  style: any;
  news: News;
}

const NewsCard = ({ news, onClick, style }: IProps) => {
  const { coverImage, excerpt, title, date } = news;
  return (
    <Container style={style} onClick={onClick}>
      <ImageBox imageUrl={setImageUrl(coverImage)} />
      <ContentBox>
        <Title>{title}</Title>
        <Date>{date}</Date>
        <Excerpt>{excerpt}</Excerpt>
      </ContentBox>
    </Container>
  );
};

const Container = styled.div`
  cursor: pointer;
  display: flex;
  margin: 0 25px 24px;
  color: ${({ theme }) => theme.mainTitle};
  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width: ${response.pad}px) {
    margin-bottom: 48px;
    flex-direction: column;
  }
`;
const ContentBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0;
  border-bottom: 1px solid ${({ theme }) => theme.listBorder};
  @media (max-width: ${response.pad}px) {
    margin-top: 24px;
    border-bottom: none;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
`;
const Date = styled.span`
  margin: 12px 0;
  font-weight: 300;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.caption};
`;

const Excerpt = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 170%;
  color: ${({ theme }) => theme.caption};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  @media (max-width: ${response.pad}px) {
    font-size: 14px;
  }
`;

const ImageBox = styled.div<{ imageUrl: string }>`
  background-color: #fff;
  border-radius: 10px;
  height: 152px;
  width: 240px;
  background: no-repeat center / cover url(${({ imageUrl }) => imageUrl});
  margin-right: 24px;
  @media (max-width: ${response.pad / 2}px) {
    width: 100%;
  }
  @media (min-width: ${response.pad / 2 +
    1}px) and (max-width: ${response.pad}px) {
    height: 230px;
    width: 100%;
  }
`;

NewsCard.defaultProps = {
  onClick: () => {},
  style: {},
  new: {
    author: "",
    coverImage: "",
    date: "",
    description: "",
    email: "",
    excerpt: "",
    slug: "",
    title: "",
  },
};

export default NewsCard;
