import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import theme, { response } from "../styles/theme";
import { nav } from "./data";
import Logo from "./Logo";
import Hamberger from "./svg/Hamberger";

interface IProps {
  fixHeader: boolean;
}

const Header = ({ fixHeader }: IProps) => {
  const [blackHeader, setBlackHeader] = useState(false);
  const [menuModal, setMenuModal] = useState(false);
  const ref = useRef(null);
  const navigate = useNavigate();

  const handleMovePage = (e: React.MouseEvent<HTMLLIElement>, url: string) => {
    e.preventDefault();
    setMenuModal(false);
    navigate(url);
  };

  useEffect(() => {
    fixHeader ? setBlackHeader(true) : setBlackHeader(false);
  }, [fixHeader]);

  return (
    <HeaderContainer
      blackHeader={blackHeader}
      fixHeader={blackHeader ? true : fixHeader}
    >
      <Center>
        <Logo
          width={100}
          height={32}
          themeMode={blackHeader ? "light" : fixHeader ? "light" : "dark"}
        />
        <GNB menuModal={menuModal} onClick={() => setMenuModal(false)}>
          <ul ref={ref}>
            {nav.map((li) => {
              return (
                <List
                  key={li.id}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    handleMovePage(e, li.url);
                  }}
                >
                  <span>{li.name}</span>
                </List>
              );
            })}
          </ul>
        </GNB>
        <MobileNav>
          <Hamberger
            fill={
              blackHeader
                ? theme.mainTitle
                : fixHeader
                ? theme.mainTitle
                : "white"
            }
            onClick={() => {
              setMenuModal(true);
            }}
          />
        </MobileNav>
      </Center>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div<{
  fixHeader: boolean;
  blackHeader: boolean;
}>`
  color: ${({ fixHeader }) => (fixHeader ? theme.mainTitle : "white")};
  position: ${({ fixHeader }) => (fixHeader ? "fixed" : "absolute")};
  background-color: ${({ fixHeader, blackHeader }) => {
    if (fixHeader) {
      return "rgba(255, 255, 255, 0.8)";
    } else if (blackHeader) {
      return "rgba(255, 255, 255, 0.8)";
    } else {
      return "transparent";
    }
  }};
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 100;
  width: 100%;
`;
const Center = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 32px;
  @media (max-width: ${response.pad}px) {
    /* min-width: ${response.mobile + "px"}; */
    width: 100%;
    height: 80px;
    padding: 0 24px;
  }
  @media (min-width: ${response.pad +
    1}px) and (max-width: ${response.desktop}px) {
    width: 100%;
    justify-content: flex-start;
  }
  @media (min-width: ${response.desktop + 1}px) {
    max-width: ${response.desktop + "px"};
    width: 100%;
  }
`;
const GNB = styled.nav<{ menuModal: boolean }>`
  /* margin: 0 32px; */
  display: flex;
  justify-content: center;
  font-size: 1rem;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  @media (min-width: ${response.pad +
    1}px) and (max-width: ${response.desktop}px) {
    flex: 1;
    justify-content: flex-end;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    li {
      opacity: 0.9;
      margin-right: 48px;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
  @media (max-width: ${response.pad}px) {
    display: ${({ menuModal }) => (menuModal ? "flex" : "none")};
    margin: 0;
    padding: 0;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    ul {
      display: flex;
      flex-direction: column;
      /* align-content: center; */
      justify-content: center;
      /* padding:0 50px; */
      li {
        display: inline;
        margin: 0;
        font-weight: 500;
        color: white;
        font-size: 28px;
        line-height: 150%;
        margin-bottom: 48px;
        &:hover,
        &:active {
          & > span {
            margin-bottom: 47px;
            border-bottom: 1px solid white;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
`;
const List = styled.li``;

const MobileNav = styled.div`
  & > div {
    &:nth-child(2) {
      margin-left: 20px;
    }
  }
  @media (max-width: ${response.pad}px) {
    display: flex;
  }
  @media (min-width: ${response.pad + 1}px) {
    display: none;
  }
`;

Header.defaultProps = {
  fixHeader: false,
};

export default Header;
