import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Layout from "../../layout";
import { response } from "../../styles/theme";
import { News } from "../home/MainContentList";
import Markdown2 from "./Markdown2";

const Detail = () => {
  const { state } = useLocation() as unknown as {
    state: News;
  };

  const { id, date, title, author, email, description } = state;

  return (
    <Layout>
      <Container>
        <Date>{date}</Date>
        <Title>{title}</Title>
        {/* <PostContent content={content} /> */}
        <Markdown2 id={id} />
        <Source>
          <p>
            {author}
            <span>{email}</span>
          </p>
          <p>{description}</p>
        </Source>
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  color: ${({ theme }) => theme.mainTitle};
  max-width: ${response.desktop - 200 + "px"};
  margin: 80px 0;
  padding: 0 64px;
  padding-top: 80px;
  box-sizing: border-box;
  width: 100%;
  @media (max-width: ${response.pad}px) {
    max-width: ${response.pad + "px"};
    padding: 30px 25px 0;
  }
`;
const Date = styled.span`
  color: ${({ theme }) => theme.caption};
  font-size: 18px;
  line-height: 150%;
  @media (max-width: ${response.pad}px) {
    font-size: 14px;
  }
`;
const Title = styled.h2`
  margin: 0;
  margin-top: 8px;
  font-weight: 500;
  font-size: 36px;
  line-height: 120%;
  @media (max-width: ${response.pad}px) {
    font-size: 24px;
  }
`;
const Source = styled.div`
  margin-top: 40px;
  color: ${({ theme }) => theme.caption};
  font-weight: 300;
  & > p {
    line-height: 150%;
    span {
      cursor: pointer;
      margin-left: 10px;
      &:hover {
        text-decoration: underline;
      }
      &::before {
        content: "|";
        margin-right: 10px;
      }
    }
  }
  @media (max-width: ${response.pad}px) {
    font-size: 12px;
  }
`;

Detail.defaultProps = {
  style: {},
  data: {
    author: "",
    coverImage: "",
    date: "",
    description: "",
    email: "",
    excerpt: "",
    slug: "",
    title: "",
  },
};

export default Detail;
