import { useCallback } from "react";
import styled from "styled-components";
import { modal } from "../store/store";
import { useSetRecoilState } from "recoil";

const ContactButton = () => {
  const setIsModal = useSetRecoilState(modal);
  const setModal = useCallback(() => {
    setIsModal(true);
  }, [setIsModal]);
  return (
    <Container>
      {/** viewMode */}
      <FixedButton onClick={setModal}>Contact</FixedButton>
    </Container>
  );
};
const Container = styled.div`
  position: absolute;
  bottom: 32px;
  right: 32px;
`;
export const FixedButton = styled.div`
  cursor: pointer;
  position: relative;
  padding: 8px 24px;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0;
  bottom: 0;
  right: 0;
  font-size: 14px;
  line-height: 1.5em;
  font-weight: 500;
  transition: 0.3s;
  &:hover {
    transition: 0.3s;
    background-color: rgba(255, 255, 255, 0.3);
  }
`;
export default ContactButton;
