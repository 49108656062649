import ReactMarkdown from "react-markdown";
import { useRecoilValue } from "recoil";
import { width } from "../../store/store";
import markdownStyles from "../../styles/markdown-styles.module.css";
import theme from "../../styles/theme";

const contents = [
  `![바디프랜드 멤버쉽2](/project/bf_membership/img_detail_membership_2.jpg)
  ![바디프랜드 멤버쉽3](/project/bf_membership/img_detail_membership_3.jpg)
  ![바디프랜드 멤버쉽4](/project/bf_membership/img_detail_membership_4.jpg)
  ![바디프랜드 멤버쉽5](/project/bf_membership/img_detail_membership_5.jpg)
  ![바디프랜드 멤버쉽6](/project/bf_membership/img_detail_membership_6.jpg)
  `,
  `![바디프랜드 멤버쉽2](/project/bf_lounge/img_detail_lounge-2.jpg)
  ![바디프랜드 멤버쉽3](/project/bf_lounge/img_detail_lounge-3.jpg)
  ![바디프랜드 멤버쉽4](/project/bf_lounge/img_detail_lounge-4.jpg)
  ![바디프랜드 멤버쉽5](/project/bf_lounge/img_detail_lounge-5.jpg)
  ![바디프랜드 멤버쉽6](/project/bf_lounge/img_detail_lounge-6.jpg)
  `,
  `![바디프랜드 멤버쉽2](/project/uno_zzami/img_detail_zzam-2.jpg)
  ![바디프랜드 멤버쉽3](/project/uno_zzami/img_detail_zzam-3.jpg)
  ![바디프랜드 멤버쉽4](/project/uno_zzami/img_detail_zzam-4.jpg)
  ![바디프랜드 멤버쉽5](/project/uno_zzami/img_detail_zzam-5.jpg)
  ![바디프랜드 멤버쉽6](/project/uno_zzami/img_detail_zzam-6.jpg)
  `,
  `![플로우진2](/project/flowjeans/img_detail_frow-2.jpg)
  ![플로우진3](/project/flowjeans/img_detail_frow-3.jpg)
  ![플로우진4](/project/flowjeans/img_detail_frow-4.jpg)
  ![플로우진5](/project/flowjeans/img_detail_frow-5.jpg)
  ![플로우진6](/project/flowjeans/img_detail_frow-6.jpg)
  `,
  `![asis2](/project/asis/img_detail_asis-2.jpg)
  ![asis3](/project/asis/img_detail_asis-3.jpg)
  ![asis4](/project/asis/img_detail_asis-4.jpg)
  ![asis5](/project/asis/img_detail_asis-5.jpg)
  ![asis6](/project/asis/img_detail_asis-6.jpg)
  `,
  `![타임픽2](/project/timepick/img_detail_timepick-2.jpg)
  ![타임픽3](/project/timepick/img_detail_timepick-3.jpg)
  ![타임픽4](/project/timepick/img_detail_timepick-4.jpg)
  ![타임픽5](/project/timepick/img_detail_timepick-5.jpg)
  ![타임픽6](/project/timepick/img_detail_timepick-6.jpg)
  `,
  `![피터팬2](/project/peterpanz/img1.svg)
  ![피터팬3](/project/peterpanz/img2.svg)
  ![피터팬4](/project/peterpanz/img3.svg)
  ![피터팬5](/project/peterpanz/img4.svg)
  `,
];

interface IProps {
  id: number;
}

const ProMarkdown = ({ id }: IProps) => {
  const innerWidth = useRecoilValue(width);

  return (
    <div className="mx-auto" style={{ color: "red" }}>
      <ReactMarkdown className={markdownStyles["mobile"]}>
        {innerWidth && innerWidth <= theme.response.pad
          ? contents[id].split(".").join("m.")
          : contents[id]}
      </ReactMarkdown>
    </div>
  );
};

ProMarkdown.defaultProps = {
  id: 0,
};

export default ProMarkdown;
